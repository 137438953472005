@font-face {
  font-family: Amarillo;
  src: url('../public/fonts/Amarillo.otf');
}

* {
  font-family: 'Ubuntu', sans-serif;
}

body {
  /* background-image: url('/public/image/backgrounds/fundo-site-arte-na-rede1.png'); */
  background-color: rgb(243, 243, 243);
}

main {
  padding: 100px 0 30px;
  
}

.carousel{
  /* object-fit: cover !important; */
  /* position: ; */
  width: 100vw;
  height: vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: Amarillo;
  color: #fff;
  font-size: 5em;
  text-decoration: underline #F79418;
}

.paragrafo {
  text-align: justify;
  padding: 0 25px;
  font-size: 20px;
}

.title-3 {
  padding: 0 25px;
}

.error-404 {
  height: 75vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .title-3 {
    padding: 15px;
  }
}
