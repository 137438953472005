.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  /* padding: 25px; */
  height: 100px;
  margin: 0;
}

/* SOCIAL */

.social i {
  margin: 0 10px;
}

.social {
  background-image: url('../../../public/image/patrocinio/fundo-footer-cima.jpg');
  background-position: center;
  background-size: 100% 100%;
  margin: 0px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.social-button {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.social-button li {
  display: flex;
  align-items: center;
  transition: 0.3s;
}

.social-button li:hover {
  opacity: 80%;
  transition: 0.3s;
}

.social-container {
  display: flex;
  color: #fff;
  /* align-items: center; */
  text-align: end;
}

.social-container img {
  margin: 0 10px;
}

.follow-us {
  display: flex;
  align-items: center;
}

.follow-us p {
  margin: 0 10px;
}

/* PATROCINADORES */

.patrocinadores {
  background-image: url('../../../public/image/patrocinio/fundo-footer-baixo.jpg');
  background-position: center;
  background-size: 100% 100%;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logos-patrocinio {
  display: flex;
  /* justify-content: space-evenly; */
}

.patro-logo p {
  margin: 0 20px;
}

.patro-logo {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
}

.copyright {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
  opacity: 75%;
}

.redemoinho {
  width: 85px;
  margin: 10px 10px 20px 0;
}

.promulti {
  width: 175px;
  margin-top: 25px;
}

.secult {
  width: 250px;
  margin: 20px 0 25px 20px;
}

.secult-pi {
  display: flex;
  width: 300px;
  padding: 20px 0px;
  font-size: small;
  margin-top: 25px;
  margin-block: 15px;
  color: rgb(48, 48, 48);
  text-decoration: none;
  border-radius: 5px;
}

.retangulo {
  width: 2px;
  margin: 0 10px 0;
}

.barra-light {
  background-color: #fff;
  width: 2px;
  height: 75px;
  margin: 20px 0;
}

.barra-dark {
  background-color: rgb(46, 46, 46);
  width: 2px;
  height: 150px;
  margin: 0 20px;
  opacity: 50%;
}

/* RESPONSIVIDADE */

@media screen and (max-width: 991px) {

  /* PATROCINADORES */
  .patrocinadores {
    padding: 5px;
    justify-content: center;
    align-items: center;
  }
  
  .logos-patrocinio {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding: 5px;
    text-align: center;
    font-size: 12px;
  }

  .redemoinho {
    width: 50px;
    margin: 5px 0 30px;
  }

  .promulti {
    width: 80px;
    margin: 23px 0 40px;
  }

  .secult {
    width: 120px;
    margin: 27px 0;
  }

  .patro-logo {
    margin-top: 25px;
    /* padding: 0; */
  }

  .copyright {
    margin: 0;
    font-size: 10px;
  }

  .barra-light {
    background-color: #fff;
    width: 150px;
    height: 2px;
    margin: 10px 0;
  }

  .barra-dark {
    background-color: rgb(46, 46, 46);
    width: 2px;
    height: 80px;
    margin: 0 5px;
    opacity: 25%;
  }

  /* SOCIAL */

  .social {
    background-image: url('../../../public/image/patrocinio/fundo-footer-cima.jpg');
    background-position: center;
    background-size: 100% 100%;
    margin: 0px;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .social-button {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }

  .social-button li {
    display: flex;
    align-items: center;
  }

  .social-button img {
    width: 25px;
  }

  .social-button li:hover {
    opacity: 80%;
    transition: 0.5s;
  }

  .social-container {
    display: flex;
    color: #fff;
    flex-direction: column;
    /* align-items: center; */
    text-align: end;
  }

  .follow-us {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .follow-us p {
    margin: 0 10px;
    font-size: 12px;
  }

  .logo-arte-na-rede {
    width: 75px !important;
  }

  .secult-pi {
    width: 150px;
    padding: 0px 0px;
    font-size: 9px;
    margin-bottom: 10px;
    margin-block: 15px;
    color: rgb(48, 48, 48);
    text-decoration: none;
    border-radius: 5px;
  }
}