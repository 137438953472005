.seminarios-blocos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.mesa-um {
    background-image: url('../../../../public/image/seminarios/mesa-01-banner.jpeg');
    background-size: 150%;
    background-position: 100%;
}

.mesa-dois {
    background-image: url('../../../../public/image/seminarios/mesa-02-banner.jpeg');
    background-size: 150%;
    background-position: 100%;
}

.mesa-tres {
    background-image: url('../../../../public/image/seminarios/mesa-03-banner.jpeg');
    background-size: 150%;
    background-position: 100%;
}