.oficina-envultacoes-btn {
    background-image: url('../../../../public/image/acoes-formativas/cursos-oficinas-residencias/oficina-envultacoes-banner.jpg');
    background-size: 100%;
}


.oficina-danceability-btn {
    background-image: url('../../../../public/image/acoes-formativas/cursos-oficinas-residencias/oficina-danceability-banner.jpg');
    background-size: 100%;
}
