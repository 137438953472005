.acoes-formativas-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.bloco-01 {
    display: flex;
}

.bloco-02 {
    display: flex;
}

.acoes-formativas-title {
    text-align: center;
}

.button-acoes-formativas {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 10px;
    width: 200px;
    transition: 0.3s;
}

.button-acoes-formativas:hover {
    opacity: 80%;
    transition: 0.3s;
}

.acoes-formativas-box {
    width: 150px;
    height: 150px;
    background-color: #F79418;
    border-radius: 20px;
    margin-bottom: 10px;
}


.aulas-livres-de-danca {
    background-image: url('../../../public/image/acoes-formativas/AULAS-LIVRES\ DE\ DANÇA.jpg');
    background-size: 100%;
}

.oficinas-dancando-pelo-piaui {
    background-image: url('../../../public/image/acoes-formativas/OFICINAS-DANCANDO-PELO-PIAUI.jpg');
    background-size: 100%;
}

.seminarios {
    background-image: url('../../../public/image/acoes-formativas/SEMINARIOS.jpg');
    background-size: 100%;
}

.outras-formacoes {
    background-image: url('../../../public/image/acoes-formativas/OUTRAS-FORMACOES.jpg');
    background-size: 100%;
}

@media screen and (max-width: 991px) {
    .button-acoes-formativas {
        margin: 5px 0px;
        width: 180px;
        font-size: 13px;
    }

    .acoes-formativas-box {
        width: 130px;
        height: 130px;
    }

}
