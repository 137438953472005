.pre-section {
    background-color: #fff;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.pre-section .title {
    color: #F79418;
    font-size: 30px;
    text-decoration: none;
}