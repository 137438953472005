.cities-sec-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20%;
    background-size: 100%;
}

.cities-text-1 {
    display: flex;
    flex-direction: column;
    color: #fff
}

.cities-sec-2 {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 20%;
    margin-top: 25px;
}

.cities-sec-3 {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 20%;
    margin-top: 25px;
}

.cities-video {
    width: 600px;
    height: 340px;
}

.cities-photos {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 450px;
}

@media screen and (max-width: 991px) {
    .cities-sec-1 {
        padding: 20px 3%;
        background-size: 300%;
    }
    
    .cities-text-1 {
        align-items: center;
        justify-content: center;
    }

    .cities-video {
        width: 300px;
        height: 170px;
    }

    .cities-sec-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 3%;
        margin: 0;
    }

    .cities-sec-3 {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 20px 3%;
        margin: 0;
    }

    .cities-photos {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        height: 200px;
    }
}
