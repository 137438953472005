.artistas-sec {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px 20%;
    margin-top: 20px;
}

.artistas-blocos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 25px;
}

/* .artistas-blocos:hover {
    opacity: 90%;
    transition: 0.3s;
} */

.artista-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.artistas-box {
    width: 175px;
    height: 175px;
    background-color: #F79418;
    border-radius: 20px;
    margin-bottom: 10px;
}

.artistas-title {
    text-align: center;
    margin: 0;
}

.icone-artistas{
    text-decoration: none;
    color: #212529;
}

.artistas-plus-container{
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
}
