#oficinas-dancando-pelo-piaui-container {
    background-size: 100% 100%;
    background-image: url('../../../../public/image/acoes-formativas/oficinas-dancando-pelo-piaui/fundo-oficinas-dancando-pelo-piaui.jpg');
}

#oficinas-title {
    background-image: url('/public/image/sobre-o-projeto/sobe-hd.jpg');
    color: #fff;
    text-align: center;
}

.oficinas-dancando-pelo-piaui-sec-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 20%;
}


@media screen and (max-width: 991px) {
    #oficinas-title {
        padding: 20px;
    }
    
    .oficinas-dancando-pelo-piaui-sec-1 {
        padding: 35px 3%;
    }

    .oficinas-title{
        font-size: x-large;
        line-height: 50px;
        margin: 0;
    }
}
