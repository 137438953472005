#acoes-formativas-container {
    background-size: cover;
    background-image: url('../../../public/image/acoes-formativas/fundo-acoes-formativas.jpg');
}

.acoes-formativas-sec-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 20%;
}

@media screen and (max-width: 991px) {
    .acoes-formativas-sec-1 {
    padding: 35px 3%;
    }
}
