.em-construcao-container {
    background-position: center;
    background-size: 100%;
    width: 100%;
    /* height: 50vh; */
    margin: 70px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.em-construcao-container img {
    width: 50%;
}
