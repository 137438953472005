#welcome {
    background-size: 2000px;
    background-position: 50%;
    transition: 1s;
}

.welcome-title {
    margin: 15% 0;
}

.section-image {
    object-fit: cover !important;
    max-width: 40%;
    margin-right: 25px;
}

.section-image img {
    width: 100%;
}

.section-text {
    width: 50%;
}

.btn {
    border-radius: 20px;
    --bs-btn-color: rgb(37, 37, 37);
    --bs-btn-bg: #F79418;
}

.btn:hover {
    color: #F79418;
}

.section {
    background-color: white;
    display: flex !important;
    justify-content: flex-start !important;
    flex-direction: column;
    border-radius: 15px;
}

.title {
    font-size: 60px;
}

.carousel {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}

.welcome-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    padding: 0;
    text-align: center;
}

.btn-2 {
    color: #fff;
    border-radius: 20px;
    background: transparent;
    border: 2px solid #fff;
    width: 150px;
    padding: 5px 20px;
    transition: 0.3s;
}

.btn-2:hover {
    color: #fff;
    background: #F79418;
    border: 2px solid #F79418;
    transition: 0.3s;
}

.arrow {
    width: x;
    margin: 35px;
}

.arrow img {
    width: 35px; 
}

/* Sobre */
.sobre-1 {
    display: flex;
    text-align: end;
    align-items: center;
    justify-content: center;
}

.apresentation-video {
    width: 508px;
    height: 300px;
}

.barra-orange {
    width: 20px;
    height: 300px;
    background-color: #F79418;
    margin: 0 20px;
}

.sobre-content-1 {
    padding: 40px 10vw;
    background-image: url('../../../public/image/backgrounds/fundo-06.jpg');
    background-size: 150%;
    background-position: -70px;
}

.content {
    margin-top: 50px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.content img {
    width: 500px;
}

.sobre-sec {
    display: flex;
    width: 40%;
}

@media screen and (max-width: 991px) {
    #welcome {
        background-position: 60% 15%;
        background-size: 250%;
    }

    .welcome-title {
        padding: 20px;
        margin-bottom: 25%;
    }

    .carousel {
        height: 90vh;
    }

    .arrow {
        width: 25px;
        margin: 35px;
    }

    .title {
        font-size: 45px;
    }

    .section {
        flex-wrap: wrap;
    }

    .content {
        flex-wrap: wrap;
    }

    /* Sobre */
    .sobre-1 {
        display: flex;
        flex-direction: column;
        text-align: end;
        align-items: center;
    }

    .apresentation-video {
        width: 300px;
        height: 170px;
    }

    .barra-orange {
        width: 10px;
        height: 200px;
        background-color: #F79418;
    }

    .sobre-content-1 {
        padding: 35px 10px;
        background-position: -170px;
        background-size: 300% 100%;
    }

    .content {
        margin-top: 50px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
    }

    .content img {
        width: 350px;
    }

    .sobre-text-2 {
        margin-bottom: 50px;
    }

    .sobre-sec {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .title {
        font-size: 7vw;
    }

    .welcome-title h5 {
        font-size: 15px;
    }
}