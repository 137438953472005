.saiba-mais-buttons {
    display: flex;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    flex-wrap: wrap;
    padding: 0 5vw 30px;
}

.bloco-01 {
    display: flex;
}

.bloco-02 {
    display: flex;
}

.button-saiba-mais {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 10px;
    width: 200px;
    transition: 0.3s;
}

.button-saiba-mais:hover {
    opacity: 80%;
    transition: 0.3s;
}

.saiba-mais-box {
    width: 150px;
    height: 150px;
    background-color: #F79418;
    border-radius: 20px;
    margin-bottom: 10px;
}


.acoes-formativas {
    background-image: url('../../../public/image/saiba-mais-images/acoes-formativas.jpg');
    background-size: 100%;
}

.ocupa-a-rede {
    background-image: url('../../../public/image/saiba-mais-images/ocupa-a-rede.jpg');
    background-size: 100%;
}

.apresentacoes {
    background-image: url('../../../public/image/saiba-mais-images/apresentacoes.jpg');
    background-size: 100%;
}

.artistas-convidades {
    background-image: url('../../../public/image/saiba-mais-images/artistas-convidades.jpg');
    background-size: 100%;
}

@media screen and (max-width: 991px) {
    .button-saiba-mais {
        margin: 5px 0px;
        width: 180px;
        font-size: 13px;
    }

    .saiba-mais-box {
        width: 130px;
        height: 130px;
    }

}
