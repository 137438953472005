header {
  width: 100%;
}

#cabeçalho {
  display: flex;
  align-items: center;

  justify-content: space-evenly;
  margin-bottom: 75px;
}

.logo-arte-na-rede {
  width: 150px;
  margin: 10px 0;
}

.menus a {
  margin: 20px;
  text-decoration: none;
  color: rgb(37, 37, 37);
  /* transition: 0.3s; */
}

.menus a:hover {
  margin: 20px;
  text-decoration: none;
  color: #F79418;
}

#navItem {
  color: rgb(37, 37, 37);
}

#navItem:hover {
  color: #F79418;
}

#head {
  justify-content: space-around;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background-color: #fff;
}

.menu {
  flex-grow: 0;
}

.menu li {
  margin: 7px;
}

.navbar-brand img {
  width: 130px;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 991px) {
  #head {
    justify-content: space-between;
  }

  .navbar-toggler {
    border: none;
    outline: 0;
    margin: 20px;
    padding: 0;
  }

  .logo-arte-na-rede {
    width: 100px !important;
    margin: 10px 0;
  }
}

/* DROPDOWN MENU */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 0 0 5px 5px;
  padding: 20px;
}

/* .dropdown-content .nav-link {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
} */

/* .dropdown-content .nav-link:hover {background-color: #ddd;} */

.dropdown:hover .dropdown-content {
  display: block;
}


/* @media screen and (max-width: 1200px) { */

/* Burger menu */
.burger-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#burger-checkbox-menu {
  position: absolute;
  opacity: 0;
}

label {
  cursor: pointer;
  position: relative;
  display: block;
  height: 22px;
  width: 37px;
}

label span {
  position: absolute;
  display: block;
  height: 5px;
  width: 100%;
  border-radius: 30px;
  background: #F79418;
  ;
  transition: 0.25s ease-in-out;
}

label span:nth-child(1) {
  top: 0;
}

label span:nth-child(2) {
  top: 10px;
}

label span:nth-child(3) {
  top: 20px;
}

#burger-checkbox-menu:checked+label span:nth-child(1) {
  transform: rotate(-45deg);
  top: 8px;
}

#burger-checkbox-menu:checked+label span:nth-child(2) {
  opacity: 0;
}

#burger-checkbox-menu:checked+label span:nth-child(3) {
  transform: rotate(45deg);
  top: 8px;
}

/* } */
/*////////////////////////////////////////////////////

/* até 1200px de largura vai virar pra versão mobile com menu sanduíche */

/* sem barra quando não fizer scroll de menu com fundo branco */