#photo-mural-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#photo-mural-general {
    width: 750px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 50px;
}

.button-photo-mural {
    width: 35px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(37, 37, 37);
    background-color: #F79418;
    cursor: pointer;
}

.button-photo-mural:hover {
    color: rgb(37, 37, 37);
    background-color: #fff;
}

.fa-solid {
    width: 25px;
    display: flex;
    justify-content: center;
}

.image-photo-mural-container {
    width: 675px;
    background-size: cover;
    height: 100%;
}

#image-photo-mural {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.plus-btn {
    padding: 5px 20px;
    margin-bottom: 20px;
    border-radius: 15px;
    color: rgb(37, 37, 37);
    background-color: #F79418;
    cursor: pointer;
}

.plus-btn:hover {
    color: rgb(37, 37, 37);
    background-color: #fff;
}

.all-photos-general {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.all-photos-container {
    width: 250px;
    margin: 20px;
    /* background-size: cover;
    height: 100%; */
}

.all-photo-container {
    /* width: 675px; */
    background-size: cover;
    height: 100%;
}

.all-photos-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media screen and (max-width: 991px) {
    #photo-mural-general {
        width: 300px;
        height: 200px;
    }

    .button-photo-mural {
        /* height: 35px;
        width: 25px; */
        /* border-radius: 500%; */
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
